import React, { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  getproductbykeyword,
  sortApiDataByPrice,
  pageId,
  filterMessage,
  conditionMessage,
} from "../redux/actions/users";
import { useSelector, useDispatch } from "react-redux";
import Loader from "./loader";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { Dropdown } from "react-bootstrap";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";

const Condition = () => {
  let dispatch = useDispatch();
  const conditionmessage = useSelector((state) => state.getconditionMessage);
  const [isloading, setIsloading] = useState(false);
  const Product_details = useSelector(
    (state) => state.getproductbykeywordReducer
  );
  const Paidshipping_data = useSelector((state) => state.getpaidData);
  const priceRange = useSelector((state) => state.rangeReducer);
  let { Product_name, bar_code } = useParams();
  const sortStatus = useSelector((state) => state.sortReducer);
  const apiUrl = process.env.REACT_APP_SERVER_BASE_URI;
  const keywordApiRoute = "getItemByKeyword?items_name";
  const barcodeApiRoute = "getActiveListingByBarcode?barcodeNumber";

  function roundUpToNearestMultiple(number, multiple) {
    return Math.ceil(number / multiple) * multiple;
  }

  let shippingCondition = async (shippedCondition) => {
    setIsloading(true); //
    Product_details.condition = shippedCondition;
    dispatch(pageId(1));
    if (Product_name) {
      if (sortStatus.sort !== "endingSoonest") {
        dispatch(
          getproductbykeyword(
            Product_name,
            Product_details.sort,
            Product_details.pagination,
            priceRange,
            "productSearchByText",
            Product_details.filter,
            Product_details.condition
          )
        );
      } else if (sortStatus.sort === "endingSoonest") {
        await axios
          .get(
            `${apiUrl}/${keywordApiRoute}=${Product_name}&offset=0&limit=1`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-EBAY-C-MARKETPLACE-ID": "EBAY_US",
              },
            }
          )
          .then((res) => {
            const oldestLsitingOffset = (
              parseFloat(res.data.data.total) - 200
            ).toString();

            const roundedOffset = roundUpToNearestMultiple(
              oldestLsitingOffset,
              200
            );
            const oldestPagination = {
              offset: roundedOffset,
              limit: 200,
            };

            dispatch(
              getproductbykeyword(
                Product_name,
                "newlyListed",
                oldestPagination,
                priceRange,
                "productSearchByText",
                Product_details.filter,
                Product_details.condition
              )
            );
          });
      }
      setTimeout(() => {
        setIsloading(false);
      }, 5000);
    }
    if (bar_code) {
      if (sortStatus.sort !== "endingSoonest") {
        dispatch(
          getproductbykeyword(
            bar_code,
            Product_details.sort,
            Product_details.pagination,
            priceRange,
            "productSearchByBarCode",
            Product_details.filter,
            Product_details.condition
          )
        );
      } else if (sortStatus.sort === "endingSoonest") {
        await axios
          .get(`${apiUrl}/${barcodeApiRoute}=${bar_code}&offset=0&limit=1`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              Accept: "application/json",
              "Content-Type": "application/json",
              "X-EBAY-C-MARKETPLACE-ID": "EBAY_US",
            },
          })
          .then((res) => {
            const oldestLsitingOffset = (
              parseFloat(res.data.data.total) - 200
            ).toString();

            const roundedOffset = roundUpToNearestMultiple(
              oldestLsitingOffset,
              200
            );
            const oldestPagination = {
              offset: roundedOffset,
              limit: 200,
            };

            dispatch(
              getproductbykeyword(
                bar_code,
                "newlyListed",
                oldestPagination,
                priceRange,
                "productSearchByBarCode",
                Product_details.filter,
                Product_details.condition
              )
            );
          });
      }
      setTimeout(() => {
        setIsloading(false);
      }, 5000);
    }
  };

  return (
    <>
      {!isloading ? (
        <div>
          <div className="option-wrapper">
            <Dropdown drop="end" className="dropdown">
              <DropdownToggle
                className="btn p-0 sort-btn dropdown-toggle"
                variant=""
              >
                <span>Condition</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32.717"
                  height="32"
                  viewBox="0 0 32.717 32"
                >
                  <path
                    id="filter_alt_FILL0_wght400_GRAD0_opsz48"
                    d="M22,40a1.964,1.964,0,0,1-2-2V26L8.05,10.75a1.513,1.513,0,0,1-.2-1.8A1.643,1.643,0,0,1,9.4,8H38.6a1.643,1.643,0,0,1,1.55.95,1.513,1.513,0,0,1-.2,1.8L28,26V38a1.964,1.964,0,0,1-2,2Zm2-13.8L36,11H12ZM24,26.2Z"
                    transform="translate(-7.642 -8)"
                  />
                </svg>
                {conditionmessage.response === "new" ? (
                  <span>&nbsp;New</span>
                ) : conditionmessage.response === "use" ? (
                  <span>&nbsp;Used</span>
                ) : conditionmessage.response === "none" ? (
                  ""
                ) : (
                  ""
                )}
              </DropdownToggle>

              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    shippingCondition("NEW");
                    dispatch(conditionMessage("new"));
                  }}
                >
                  New
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    shippingCondition("USED");
                    dispatch(conditionMessage("use"));
                  }}
                >
                  Used
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    shippingCondition("");
                    dispatch(conditionMessage("none"));
                  }}
                >
                  None
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>

            {/* <button className="btn p-0 sort-btn dropdown-toggle"
                        data-bs-toggle="dropdown" aria-expanded="false"><span>Condition</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32.717" height="32"
                            viewBox="0 0 32.717 32">
                            <path id="filter_alt_FILL0_wght400_GRAD0_opsz48"
                                d="M22,40a1.964,1.964,0,0,1-2-2V26L8.05,10.75a1.513,1.513,0,0,1-.2-1.8A1.643,1.643,0,0,1,9.4,8H38.6a1.643,1.643,0,0,1,1.55.95,1.513,1.513,0,0,1-.2,1.8L28,26V38a1.964,1.964,0,0,1-2,2Zm2-13.8L36,11H12ZM24,26.2Z"
                                transform="translate(-7.642 -8)" />
                        </svg>
                        {conditionmessage.response ==='new' ? <span>&nbsp;New</span> : conditionmessage.response === 'use' ? <span>&nbsp;Used</span> : conditionmessage.response === 'none' ? '' :''}
                    </button>
                    <ul className="dropdown-menu dropdown-menu-end ">
                         <li><span className="dropdown-item"  
                          onClick={() => {shippingCondition('NEW');dispatch(conditionMessage('new'))}}
                         type="button">New</span></li>
                         <li><span className="dropdown-item" 
                         onClick={() => {shippingCondition('USED');dispatch(conditionMessage('use'))}}
                         type="button">Used</span></li>
                        <li><span className="dropdown-item" 
                         onClick={() => {shippingCondition('');dispatch(conditionMessage('none'))}}
                         type="button">None</span></li>
                    </ul> */}
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default Condition;
