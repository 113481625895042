import * as type from "../types";

const initialState = {
  users: [],
  loading: false,
  error: null,
  response: {},
};
const initialPage = {
  val: "",
};

const PageNum = {
  response: 1,
};

const initialPerPage = {
  response: 10,
};
const initialRole = {
  response: "",
};
const initialMessage = {
  response: null,
};
const initialSubscribed = {
  response: true,
};

const initialkeyword = {
  response: "",
};
const initialupdatedData = {
  response: {},
};

const MinPageRange = {
  response: 0,
};
const MaxPageRange = {
  response: 5,
};

const initialRange = {
  minVal: 1,
  maxVal: 1000,
  currentMinVal: 1,
  currentMaxVal: 1000000000,
};

const SubscribedType = {
  response: "",
};

let initialPayload = {
  users: [],
  loading: false,
  error: null,
  response: {
    response: {
      data: {
        data: {
          loading: false,
          total: 0,
        },
      },
    },
  },
  sort: null,
  pagination: {
    offset: 0,
    limit: 200,
  },
  filter: null,
  condition: "",
};

const sortObj = {
  sort: null,
};

const createFolderPayload = {
  loading: false,
  error: null,
  response: { data: {} },
};

const allFolderPayload = {
  response: [],
};

export function signinReducer(state = initialState, action) {
  let response = action;

  switch (action.type) {
    case type.GET_USER_LOGIN:
      return {
        ...state,
        loading: true,
      };
    case type.GET_USER_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        response,
      };
    case type.GET_USER_LOGIN_FAILED:
      return {
        ...state,
        loading: false,
        response,
        error: action.message,
      };
    default:
      return state;
  }
}

export function profileReducer(state = initialState, action) {
  let response = action;

  switch (action.type) {
    case type.GET_COMPLETE_PROFILE:
      return {
        ...state,
        loading: true,
      };
    case type.GET_COMPLETE_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        response,
      };
    case type.GET_COMPLETE_PROFILE_FAILED:
      return {
        ...state,
        loading: false,
        response,
        error: action.message,
      };
    default:
      return state;
  }
}

export function getproductbykeywordReducer(state = initialPayload, action) {
  let response = action;
  switch (action.type) {
    case type.GET_PRODUCTS_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case type.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        response: response,
      };
    case type.GET_PRODUCTS_FAILED:
      return {
        ...state,
        loading: false,
        response,
        error: action.message,
      };

    case type.CHECK_RENDER_COMPONENT:
      return {
        redner: response.payload + 1,
      };
    default:
      return state;
  }
}

export function getsoldproductbykeywordReducer(state = initialPayload, action) {
  let response = action;

  switch (action.type) {
    case type.GET_SOLD_PRODUCTS_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case type.GET_SOLD_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        response: response,
      };
    case type.GET_SOLD_PRODUCTS_FAILED:
      return {
        ...state,
        loading: false,
        response,
        error: action.message,
      };
    default:
      return state;
  }
}

export function loaderReducer(state = initialState, action) {
  let response = action;
  switch (action.type) {
    case type.GET_LOADING_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case type.GET_LOADING_SUCCESS:
      return {
        ...state,
        loading: false,
        response,
      };
    case type.GET_LOADING_FAILED:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    default:
      return state;
  }
}

export function sortReducer(state = sortObj, action) {
  let response = action.payload;

  switch (action.type) {
    case type.SORT_PRODUCT:
      return {
        ...state,
        sort: response,
      };
    default:
      return state;
  }
}

export function rangeReducer(state = initialRange, action) {
  let response = action;
  switch (action.type) {
    case type.GET_UPDATEA:
      state.currentMinVal = parseInt(action.payload);
      if (state.currentMinVal > state.currentMaxVal) {
        state.currentMaxVal = parseInt(action.payload);
      }
      return { ...state };
    case type.GET_UPDATEB:
      state.currentMaxVal = parseInt(action.payload);

      if (state.currentMinVal > state.currentMaxVal) {
        state.currentMinVal = parseInt(action.payload);
      }
      return { ...state };
    default:
      return state;
  }
}

let initialProductData = {
  data: {},
};
export function singleProductDataReducer(state = initialProductData, action) {
  let response = action;
  switch (action.type) {
    case "SINGLE_PRODUCT_DATA":
      return {
        ...state,
        data: response.payload,
      };
    default:
      return state;
  }
}

export function getsubscriptionId(state = initialState, action) {
  let response = action.payload;

  switch (action.type) {
    case "GET_SUBSCRIPTION_ID":
      return {
        ...state,
        response: response,
      };
    default:
      return state;
  }
}

export function getpageId(state = PageNum, action) {
  let response = action.payload;
  switch (action.type) {
    case "GET_PAGE_ID":
      return {
        ...state,
        response: response,
      };
    default:
      return state;
  }
}

export function getroleId(state = initialRole, action) {
  let response = action.payload;
  switch (action.type) {
    case "GET_ROLE_ID":
      return {
        ...state,
        response: response,
      };
    default:
      return state;
  }
}

export function getsortMessage(state = initialMessage, action) {
  let response = action.payload;
  switch (action.type) {
    case "GET_SORT":
      return {
        ...state,
        response: response,
      };
    default:
      return state;
  }
}

export function getfilterMessage(state = initialMessage, action) {
  let response = action.payload;
  switch (action.type) {
    case "GET_FILTER_MESSAGE":
      return {
        ...state,
        response: response,
      };
    default:
      return state;
  }
}

export function getconditionMessage(state = initialMessage, action) {
  let response = action.payload;
  switch (action.type) {
    case "GET_CONDIION_MESSAGE":
      return {
        ...state,
        response: response,
      };
    default:
      return state;
  }
}

export function getcancelSubscription(state = initialSubscribed, action) {
  let response = action.payload;
  switch (action.type) {
    case "GET_CANCEL_SUBSCRIPTION":
      return {
        ...state,
        response: response,
      };
    default:
      return state;
  }
}

export function getsearchKeyword(state = initialkeyword, action) {
  let response = action.payload;
  switch (action.type) {
    case "GET_SEARCH_KEYWORD":
      return {
        ...state,
        response: response,
      };
    default:
      return state;
  }
}

export function getsearchBarcode(state = initialkeyword, action) {
  let response = action.payload;
  switch (action.type) {
    case "GET_SEARCH_BARCODE":
      return {
        ...state,
        response: response,
      };
    default:
      return state;
  }
}

export function getpaidData(state = initialPage, action) {
  let response = action.payload;
  switch (action.type) {
    case "GET_PAID_DATA":
      return {
        ...state,
        response: response,
      };
    default:
      return state;
  }
}

export function getperPageData(state = initialPerPage, action) {
  let response = action.payload;
  switch (action.type) {
    case "GET_PERPAGE_DATA":
      return {
        ...state,
        response: response,
      };
    default:
      return state;
  }
}

export function getbackButton(state = initialSubscribed, action) {
  let response = action.payload;
  switch (action.type) {
    case "GET_BACK_DATA":
      return {
        ...state,
        response: response,
      };
    default:
      return state;
  }
}

export function getupdatedData(state = initialupdatedData, action) {
  let response = action.payload;
  switch (action.type) {
    case "UPDATED_DATA":
      return {
        ...state,
        response: response,
      };
    default:
      return state;
  }
}

export function getMinPageNumber(state = MinPageRange, action) {
  let response = action.payload;
  switch (action.type) {
    case "MINPAGE_NUMBER":
      return {
        ...state,
        response: response,
      };
    default:
      return state;
  }
}
export function getMaxPageNumber(state = MaxPageRange, action) {
  let response = action.payload;
  switch (action.type) {
    case "MAXPAGE_NUMBER":
      return {
        ...state,
        response: response,
      };
    default:
      return state;
  }
}

export function getsubscriptionType(state = SubscribedType, action) {
  let response = action.payload;
  switch (action.type) {
    case "GET_TYPE_SUBSCRIPTION":
      return {
        ...state,
        response: response,
      };
    default:
      return state;
  }
}
