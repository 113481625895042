import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Mainheader from "../components/mainheader";
import Scanbutton from "../components/scanBtn";
import Modal from "react-bootstrap/Modal";
import { useNavigate, useParams } from "react-router-dom";
import {
  getmaxPageNumber,
  cancelSubscribed,
  updatedData,
  getproductbykeyword,
  getsoldproductbykeyword,
  sortApiDataByPrice,
  searchKeyword,
  perPageData,
  searchBarcode,
  sortMessage,
  filterMessage,
  pageId,
  backButton,
  conditionMessage,
} from "../redux/actions/users";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../components/loader";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { checkSubscription } from "../helpers/common";

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [btnhandle, setBtnhandle] = useState(false);
  const [searchdata, setSearchdata] = useState("");
  const [isloading, setIsloading] = useState(false);
  const [barcode, setBarcode] = useState("");
  const Product_details = useSelector(
    (state) => state.getproductbykeywordReducer
  );
  const priceRange = useSelector((state) => state.rangeReducer);
  const searchdatakeyword = useSelector((state) => state.getsearchKeyword);
  const searchdatabarcode = useSelector((state) => state.getsearchBarcode);
  const [barCoderSerachByUser, setBarCoderByUser] = useState("");

  useEffect(() => {
    checkSubscription();
  }, []);

  //getverodetails on keyword search
  let getVerodetails = (e) => {
    setBtnhandle(true);
    e.preventDefault();
    Product_details.sort = null;
    Product_details.filter = 1;
    Product_details.condition = "";
    Product_details.pagination.offset = 0;
    dispatch(sortApiDataByPrice(null));
    dispatch(pageId(1));
    dispatch(backButton(true));
    dispatch(getmaxPageNumber(5));
    dispatch(filterMessage("both"));
    dispatch(conditionMessage("none"));
    dispatch(perPageData(10));
    if (barCoderSerachByUser !== "") {
      getBarcodeData(barCoderSerachByUser);
    } else {
      axios
        .post(
          `${process.env.REACT_APP_SERVER_BASE_URI}/getvero`,
          {
            keyword: searchdata,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          setBtnhandle(false);
          if (res.data.data.length > 0) {
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div>
                    <Modal
                      className="modal fade modal-lg"
                      show={true}
                      onClose={onClose}
                    >
                      <Modal.Header className="justify-content-center align-items-start gap-1">
                        <span className="verohero_icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="40"
                            width="50"
                            viewBox="0 0 512 512"
                          >
                            <path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z" />
                          </svg>
                        </span>
                        <strong>
                          <h4>VERO Heroes</h4>
                        </strong>
                        <span className="verohero_icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="40"
                            width="50"
                            viewBox="0 0 512 512"
                          >
                            <path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z" />
                          </svg>
                        </span>
                      </Modal.Header>
                      <form>
                        <Modal.Body>
                          <>
                            One or more of the words you searched is a match to
                            the VERO Heroes watchlist. The products/companies on
                            our VERO list have previously filed an IP complaint
                            against sellers.
                            <br />
                            <br />
                            Some brands allow the resale of their products under
                            defined conditions, you should conduct your own
                            research to consider listing or not. This is not
                            legal advice.
                            <br />
                            <br />
                            VERO Heroes Match Terms:
                            <br />
                            <strong>
                              {res.data.data.map((list) => {
                                return (
                                  <>
                                    {list.Company_Name}
                                    <br />
                                  </>
                                );
                              })}
                            </strong>
                          </>
                        </Modal.Body>
                        <Modal.Footer>
                          <button
                            type="button"
                            onClick={() => {
                              setMostRecentSearch({
                                type: "keyword",
                                term: searchdata,
                              });
                              saveCompHistoryWithfile({ keyword: searchdata });
                              dispatch(getproductbykeyword({}));
                              dispatch(sortApiDataByPrice({}));
                              onClose();
                            }}
                            className="btn btn-success"
                          >
                            Ok
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              onClose();
                              navigate("/home");
                            }}
                            className="btn btn-danger"
                          >
                            Cancel
                          </button>
                        </Modal.Footer>
                      </form>
                    </Modal>
                  </div>
                );
              },
            });
          } else {
            setMostRecentSearch({ type: "keyword", term: searchdata });
            saveCompHistoryWithfile({ keyword: searchdata });
            dispatch(getproductbykeyword({}));
          }
        })
        .catch((err) => {});
    }
  };

  //save the keyword search history.
  let saveCompHistoryWithfile = async (data) => {
    await axios
      .post(`${process.env.REACT_APP_SERVER_BASE_URI}/comphistory`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((data) => {
        searchdata && navigate(`/searchlist/${searchdatakeyword.response}`);

        setIsloading(true);
      })
      .catch((err) => {});
  };

  let saveCompHistoryBarcode = (data) => {
    axios
      .post(`${process.env.REACT_APP_SERVER_BASE_URI}/comphistory`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        navigate(`/search-list-by-barcode/${searchdatabarcode.response}`);

        setIsloading(true);
      })
      .catch((err) => {
        throw err;
      });
  };

  let getBarcodeData = (barcode) => {
    if (barcode) {
      saveCompHistoryBarcode({
        keyword: barcode,
      });
    }
  };

  let setMostRecentSearch = (type, term) => {
    const newSearch = {
      searchType: type,
      searchTerm: term,
    };

    const newSearchString = JSON.stringify(newSearch);
    localStorage.setItem("mostRecentSearch", newSearchString);
  };

  return (
    <div className="bg-img-search">
      <Mainheader />
      <div className="container">
        <div className="page_wrapper">
          {!isloading ? (
            <main className="main_content_wrapper">
              <div className="row">
                <div className="col-12 text-center">
                  <form onSubmit={getVerodetails}>
                    <div>
                      <h1 className="home_heading">Know before you buy</h1>
                      {/*  Scanner for mobile start */}
                      <div className="mobile-scanner-wrapper">
                        <div className="take-picture"></div>
                        <div className="tap-scan"></div>
                      </div>
                      {/* Scanner for mobile end */}
                      <div className="search_bar-wrapper align-items-center text-start">
                        <div className="btn-grp_wrapper d-flex">
                          <span className="screnQrCustm">
                            <input
                              type="text"
                              name="search"
                              id="search"
                              pattern="[A-Za-z0-9!@$&\/-%_\s]{3,}"
                              title="Must contain at least 3 or more characters"
                              disabled={
                                barCoderSerachByUser !== "" ||
                                searchdatabarcode.response.length > 0
                                  ? true
                                  : false
                              }
                              value={searchdata}
                              autoComplete="off"
                              onChange={(e) => {
                                setSearchdata(e.target.value);
                                dispatch(searchBarcode(""));
                              }}
                              placeholder="Search for product"
                              className="form-control"
                            />
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="525.113"
                              height="524.986"
                              viewBox="0 0 525.113 524.986"
                            >
                              <path
                                id="search"
                                d="M315.07,17.566c-125.43,0-227.63,102.07-227.63,227.5s102.2,227.47,227.63,227.47a226.3,226.3,0,0,0,147.76-54.895L582.6,537.411A17.545,17.545,0,1,0,607.412,512.6l-119.73-119.73a226.318,226.318,0,0,0,54.891-147.79c0-125.43-102.06-227.5-227.5-227.5Zm0,34.863a192.555,192.555,0,1,1-192.63,192.64A192.33,192.33,0,0,1,315.07,52.429Z"
                                transform="translate(-87.44 -17.566)"
                              />
                            </svg>
                          </span>
                          <Scanbutton
                            getBarCode={setBarCoderByUser}
                            disabledBarInputField={
                              searchdata.length ||
                              searchdatakeyword.response.length
                            }
                          />
                        </div>
                      </div>
                      <button
                        type="submit"
                        disabled={
                          searchdatakeyword.response.length > 0 ||
                          searchdata.length > 0 ||
                          searchdatabarcode.response.length > 0 ||
                          barCoderSerachByUser !== ""
                            ? btnhandle == true
                              ? true
                              : false
                            : true
                        }
                        onClick={() => {
                          dispatch(searchKeyword(searchdata));
                        }}
                        className="btn action_btn"
                      >
                        Let's Go!
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </main>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </div>
  );
};
export default Home;
