import React, { useEffect, useState, useRef, useMemo } from "react";
import Sortfiltericon from "./sortFilter";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./loader";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import {
  getmaxPageNumber,
  getminPageNumber,
  updatedData,
  checkRenderComp,
  getsoldproductbykeyword,
  singleProductData,
  getActiveData,
  paidShipping,
  getproductbykeyword,
  perPageData,
  pageId,
  backButton,
} from "../redux/actions/users";
import Filter from "./filter";
import Condition from "./conditionfilter";
import DropdownButton from "react-bootstrap/DropdownButton";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";

const Activelist = (props) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState(false);
  const pageTopRef = useRef(null);
  const [folderNames, setFolderNames] = useState([]);
  const [nextData, setNextData] = useState("");
  const [product, setProduct] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const Product_details = useSelector(
    (state) => state.getproductbykeywordReducer
  );
  const pageMaxNumberData = useSelector((state) => state.getMaxPageNumber);
  const pageMinNumberData = useSelector((state) => state.getMinPageNumber);
  const updateData = useSelector((state) => state.getupdatedData);
  const backData = useSelector((state) => state.getbackButton);
  const priceRange = useSelector((state) => state.rangeReducer);
  const pageRange = useSelector((state) => state.getpageId);
  const Perpage_Data = useSelector((state) => state.getperPageData);
  const sortStatus = useSelector((state) => state.sortReducer);
  let { Product_name, bar_code } = useParams();
  const [show, setShow] = useState(false);
  const [nexturl, setNexturl] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagenumberlimit, setPagenumberlimit] = useState(5);
  const [count, setCount] = useState(0);
  const [render, setRender] = useState(0);
  const [ndays, setNdays] = useState(0);
  const [tcost, setTcost] = useState(0);
  const [lcost, setLcost] = useState(0);
  const [lday, setLday] = useState(0);
  const [hcost, setHcost] = useState(0);
  const [hday, setHday] = useState(0);
  const [medianPrice, setMedianPrice] = useState(0);
  const [medianDays, setMedianDays] = useState(0);
  const [backstatus, setBackstatus] = useState(true);
  const [maxpagenumberlimit, setMaxPagenumberlimit] = useState(5);
  const [minpagenumberlimit, setMinPagenumberlimit] = useState(0);
  const [pageLength, setPageLength] = useState(0);
  const [paidCount, setPaidCount] = useState(200);
  const postsperpage = Perpage_Data.response;
  const apiUrl = process.env.REACT_APP_SERVER_BASE_URI;
  const ApiRoute = "getItemByKeyword?items_name";

  useEffect(() => {
    totalNdays();
    totalCost();
    getPriceMedian();
    getDaysMedian();
    lowestCost();
    lowestDays();
    highestDays();
    highestCost();
  }, [product.length]);

  useEffect(() => {
    setTimeout(() => {
      setCurrentPage(parseFloat(pageRange.response));
    }, 1000);
    totalNdays();
    totalCost();
    getPriceMedian();
    getDaysMedian();
    highestDays();
    highestCost();
  }, [pageRange]);

  useEffect(() => {
    totalNdays();
    totalCost();
    getPriceMedian();
    getDaysMedian();
    lowestCost();
    lowestDays();
    highestDays();
    highestCost();
  });

  useEffect(() => {
    if (pageRange.response === 0 || pageRange.response === 1) {
      setCurrentPage(1);
      setMinPagenumberlimit(0);
      setMaxPagenumberlimit(5);
    } else {
      setMinPagenumberlimit(pageMinNumberData.response);
      setMaxPagenumberlimit(pageMaxNumberData.response);
    }
  }, [pageRange]);

  if (Product_details.filter === 3) {
    dispatch(
      paidShipping(
        Product_details.response.response &&
          Product_details.response.response.data.data.itemSummaries.length
      )
    );
  }

  useEffect(() => {
    setCount(count + 1);
  }, []);

  useEffect(() => {
    setIsloading(true);
    if (Product_name) {
      setTimeout(() => {
        setIsloading(false);
      }, 3000);
      if (Product_details.pagination.limit >= 20) {
        dispatch(
          getproductbykeyword(
            Product_name,
            sortStatus.sort,
            Product_details.pagination,
            priceRange,
            "productSearchByText",
            Product_details.filter,
            Product_details.condition
          )
        );
      } else {
        dispatch(
          getproductbykeyword(
            Product_name,
            sortStatus.sort,
            Product_details.pagination,
            priceRange,
            "productSearchByText",
            Product_details.filter,
            Product_details.condition
          )
        );
      }
    }
    if (bar_code) {
      setTimeout(() => {
        setIsloading(false);
      }, 3000);
      if (Product_details.pagination.limit >= 20) {
        dispatch(
          getproductbykeyword(
            bar_code,
            Product_details.sort,
            Product_details.pagination,
            priceRange,
            "productSearchByBarCode",
            Product_details.filter,
            Product_details.condition
          )
        );
      } else {
        dispatch(
          getproductbykeyword(
            bar_code,
            Product_details.sort,
            Product_details.pagination,
            priceRange,
            "productSearchByBarCode",
            Product_details.filter,
            Product_details.condition
          )
        );
      }
    }
  }, [Product_name, bar_code, render]);

  useEffect(() => {
    if (Product_details.response.response !== undefined) {
      if (
        Product_details.response.response.data.data.itemSummaries !== undefined
      ) {
        setNexturl(Product_details.response.response.data.data.next);
        if (sortStatus.sort === "endingSoonest") {
          // sort set to oldest listing
          const SortedData = Product_details.response.response.data.data.itemSummaries
            .map((obj) => {
              return { ...obj, date: new Date(obj.itemCreationDate) };
            })
            .sort((a, b) => b.date - a.date);
          setProduct(SortedData.reverse());
        } else if (sortStatus.sort === "newlyListed") {
          // sort set to newtest listing
          const SortedData = Product_details.response.response.data.data.itemSummaries
            .map((obj) => {
              return { ...obj, date: new Date(obj.itemCreationDate) };
            })
            .sort((a, b) => b.date - a.date);
          setProduct(Product_details.response.response.data.data.itemSummaries);
        } else if (sortStatus.sort === "-price") {
          // sort set to high to low
          const SortedData = Product_details.response.response.data.data.itemSummaries.sort(
            (a, b) => {
              const priceA = parseFloat(a.price.value);
              const priceB = parseFloat(b.price.value);

              return priceA - priceB;
            }
          );
          setProduct(SortedData.reverse());
        } else if (sortStatus.sort === "price") {
          // sort set to lowest listing
          const SortedData = Product_details.response.response.data.data.itemSummaries.sort(
            (a, b) => {
              const priceA = parseFloat(a.price.value);
              const priceB = parseFloat(b.price.value);

              return priceA - priceB;
            }
          );
          setProduct(SortedData);
        } else {
          if (backData.response) {
            setProduct(
              Product_details.response.response.data.data.itemSummaries
            );
            dispatch(
              updatedData(
                Product_details.response.response.data.data.itemSummaries
              )
            );
          } else {
            setProduct(updateData.response);
          }
        }
      }
    }
  }, [Product_details, render]);

  if (Product_details.response.response !== undefined) {
    if (
      Product_details.response.response.data.data.itemSummaries !== undefined
    ) {
      var length = product.length;
    }
  }

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(length / postsperpage); i++) {
    pageNumbers.push(i);
  }

  const indexofLastPost = currentPage * postsperpage;
  const indexofFirstPost = indexofLastPost - postsperpage;
  var lastdata = indexofLastPost;
  const currentPosts =
    product.length !== 0
      ? product.slice(indexofFirstPost, indexofLastPost)
      : [];

  const paginate = (page) => {
    setCurrentPage(page);
  };

  const renderpagination = pageNumbers.map((number) => {
    if (number < maxpagenumberlimit + 1 && number > minpagenumberlimit) {
      return (
        <li
          key={number}
          className={number == currentPage ? "page-item active" : "page-item"}
        >
          <a
            onClick={() => {
              paginate(number);
            }}
            className="page-link  "
          >
            {number}
          </a>
        </li>
      );
    } else {
      return null;
    }
  });

  const handlenext = () => {
    if (currentPage < pageNumbers.length) {
      setCurrentPage(currentPage + 1);
      if (currentPage + 1 > maxpagenumberlimit) {
        setMaxPagenumberlimit(maxpagenumberlimit + pagenumberlimit);
        setMinPagenumberlimit(minpagenumberlimit + pagenumberlimit);
        dispatch(getmaxPageNumber(maxpagenumberlimit + pagenumberlimit));
        dispatch(getminPageNumber(minpagenumberlimit + pagenumberlimit));
      }
    }

    if (
      currentPage == pageNumbers.length &&
      Product_details.filter !== 3 &&
      product.length % postsperpage == 0
    ) {
      handlepagination();
      if (currentPage + 1 > maxpagenumberlimit) {
        setMaxPagenumberlimit(maxpagenumberlimit + pagenumberlimit);
        setMinPagenumberlimit(minpagenumberlimit + pagenumberlimit);
        dispatch(getmaxPageNumber(maxpagenumberlimit + pagenumberlimit));
        dispatch(getminPageNumber(minpagenumberlimit + pagenumberlimit));
      }
    }

    if (Product_details.filter === 3 && nexturl !== undefined) {
      handlepagination();
      setCurrentPage(currentPage);
      if (currentPage + 1 > maxpagenumberlimit) {
        setMaxPagenumberlimit(maxpagenumberlimit + pagenumberlimit);
        setMinPagenumberlimit(minpagenumberlimit + pagenumberlimit);
        dispatch(getmaxPageNumber(maxpagenumberlimit + pagenumberlimit));
        dispatch(getminPageNumber(minpagenumberlimit + pagenumberlimit));
      }
    }
  };

  const endingSoonPagination = async () => {
    let newOffset = (
      parseFloat(Product_details.pagination.offset) - 200
    ).toString();
    console.log(newOffset);
    await axios
      .get(
        `${apiUrl}/${ApiRoute}=${Product_name}&offset=${newOffset}&limit=200&sort=newlyListed&filter=${Product_details.filter}&condition=${Product_details.condition}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-EBAY-C-MARKETPLACE-ID": "EBAY_US",
          },
        }
      )
      .then((res) => {
        // const newdata = res.data.data.itemSummaries.sort()
        console.log(res);
        if (res.data.data.itemSummaries !== undefined) {
          console.log("res not null");
          setIsloading(true);
          const SortedDate = [...product, ...res.data.data.itemSummaries]
            .map((obj) => {
              return { ...obj, date: new Date(obj.itemCreationDate) };
            })
            .sort((a, b) => b.date - a.date);
          setProduct(SortedDate.reverse());
        } else {
          alert("Problem adding more listings");
        }
      });
    setTimeout(() => {
      setIsloading(false);
    }, 5000);
  };

  const handlepagination = () => {
    if (sortStatus.sort === "endingSoonest") {
      endingSoonPagination();
    } else {
      if (product.length !== 0) {
        if (
          Product_details.response.response.data.data.next &&
          Product_details.response.response.data.data.next !== undefined &&
          nexturl !== undefined
        ) {
          setIsloading(true);
          let APIURL =
            nexturl.length > 0
              ? nexturl
              : Product_details.response.response.data.data.next;
          axios
            .post(`${process.env.REACT_APP_SERVER_BASE_URI}/getpagination`, {
              apiURl: APIURL,
            })
            .then((response) => {
              setNexturl(response.data.data.next);
              setIsloading(false);
              if (response.data.data.itemSummaries !== undefined) {
                if (sortStatus.sort === "newlyListed") {
                  const SortedData = [
                    ...product,
                    ...response.data.data.itemSummaries,
                  ]
                    .map((obj) => {
                      return { ...obj, date: new Date(obj.itemCreationDate) };
                    })
                    .sort((a, b) => b.date - a.date);
                  setProduct(SortedData);
                } else if (sortStatus.sort === "-price") {
                  // sort set to high to low
                  const SortedData = [
                    ...product,
                    ...response.data.data.itemSummaries,
                  ].sort((a, b) => {
                    const priceA = parseFloat(a.price.value);
                    const priceB = parseFloat(b.price.value);

                    return priceA - priceB;
                  });
                  setProduct(SortedData);
                } else if (sortStatus.sort === "price") {
                  // sort set to lowest listing
                  const SortedData = [
                    ...product,
                    ...response.data.data.itemSummaries,
                  ].sort((a, b) => {
                    const priceA = parseFloat(a.price.value);
                    const priceB = parseFloat(b.price.value);

                    return priceA - priceB;
                  });
                  setProduct(SortedData);
                }
                // else if (Product_details.filter === 3) {
                //   let paiddata = [];
                //   const SortPaid = response.data.data.itemSummaries.map(
                //     (paidShipping) => {
                //       return (
                //         paidShipping.shippingOptions !== undefined &&
                //         paidShipping.shippingOptions.map((e) => {
                //           if (e.shippingCost !== undefined) {
                //             if (
                //               parseFloat(e.shippingCost.value).toFixed() > 0
                //             ) {
                //               return product.push(paidShipping);
                //             }
                //           }
                //         })
                //       );
                //     }
                //   );
                // }
                else {
                  setProduct((product) => [
                    ...product,
                    ...response.data.data.itemSummaries,
                  ]);
                  dispatch(
                    updatedData([
                      ...product,
                      ...response.data.data.itemSummaries,
                    ])
                  );
                }
              } else {
                setStatus(true);
              }
            })
            .catch((err) => {
              throw err;
            });
        }
      }
    }
  };

  const handleprevious = async () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      if ((currentPage - 1) % pagenumberlimit == 0) {
        setMaxPagenumberlimit(maxpagenumberlimit - pagenumberlimit);
        setMinPagenumberlimit(minpagenumberlimit - pagenumberlimit);
        dispatch(getmaxPageNumber(maxpagenumberlimit - pagenumberlimit));
        dispatch(getminPageNumber(minpagenumberlimit - pagenumberlimit));
      }
    }
  };
  //get all the list of folders
  let getAllFolders = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_BASE_URI}/listOfFolder`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        if (response.data.data.length > 0) {
          setShow(false);
          setFolderNames(response.data.data);
        } else {
          setShow(false);
        }
      })
      .catch((err) => {});
  };

  //save the product in the folder
  let saveProduct = (folderID, productdata) => {
    setIsloading(true);
    axios
      .post(
        `${process.env.REACT_APP_SERVER_BASE_URI}/saveproudctwithfolder`,
        {
          folderId: folderID,
          product: productdata,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setIsloading(false);

        if (response.data.status === 200) {
          toast.success("Product saved successfully");
        }
      })
      .catch((err) => {
        setIsloading(false);
        toast.error(err.response.data.message);
      });
  };

  var endDate = new Date().toDateString();
  //calculate the average days of listing
  const totalNdays = () => {
    var total = 0;
    product.length !== 0 &&
      product.map((Sitem) => {
        var endDate = new Date().toDateString();
        total += parseFloat(
          new Date(
            new Date(endDate) -
              new Date(new Date(Sitem.itemCreationDate).toDateString())
          ) / (1000 * 60 * 60 * 24).toFixed()
        );
      });
    setNdays(total);
  };

  //calculate the average cost of listing
  const totalCost = () => {
    var Sum = 0;
    product.length !== 0 &&
      product.map((Scost) => {
        Sum += parseFloat(Scost.price && Scost.price.value);
      });
    setTcost(Sum);
  };

  //calculate Median of cost of listing
  const getPriceMedian = () => {
    var m =
      product.length !== 0 &&
      product
        .map(function(v) {
          return parseFloat(v.price && v.price.value);
        })
        .sort(function(a, b) {
          return a - b;
        });
    var middle = Math.floor((m.length - 1) / 2);
    if (m.length % 2) {
      setMedianPrice(m[middle]);
      // return m[middle];
    } else {
      setMedianPrice((m[middle] + m[middle + 1]) / 2.0);
      // return (m[middle] + m[middle + 1]) / 2.0;
    }
  };

  //calculate Median of Days of listing
  const getDaysMedian = () => {
    var m =
      product.length !== 0 &&
      product
        .map(function(d) {
          return parseFloat(
            new Date(
              new Date(endDate) -
                new Date(new Date(d.itemCreationDate).toDateString())
            ) / (1000 * 60 * 60 * 24).toFixed()
          );
        })
        .sort(function(a, b) {
          return a - b;
        });

    var middle = Math.floor(m.length / 2);
    if (m.length % 2) {
      setMedianDays(m[middle]);
      // return m[middle];
    } else {
      setMedianDays((m[middle] + m[middle + 1]) / 2.0);
      // return (m[middle] + m[middle + 1]) / 2.0;
    }
  };

  //calculate the lowest cost  and lowest days of listing
  const lowestCost = () => {
    const lowest = [];
    product.length !== 0 &&
      product.map((lcost) => {
        lowest.push(lcost.price && parseFloat(lcost.price.value));
      });
    setLcost(Math.min(...lowest));
  };

  const lowestDays = () => {
    const lowest = [];
    product.length !== 0 &&
      product.map((ldays) => {
        lowest.push(
          parseFloat(
            new Date(
              new Date(endDate) -
                new Date(new Date(ldays.itemCreationDate).toDateString())
            ) / (1000 * 60 * 60 * 24).toFixed()
          )
        );
      });
    setLday(Math.min(...lowest));
  };
  //calculate the highest cost  and highest days of listing
  const highestCost = () => {
    const highest = [];
    product.length !== 0 &&
      product.map((hcost) => {
        highest.push(hcost.price && parseFloat(hcost.price.value));
      });
    setHcost(Math.max(...highest));
  };

  const highestDays = () => {
    const highest = [];
    product.length !== 0 &&
      product.map((hdays) => {
        highest.push(
          parseFloat(
            new Date(
              new Date(endDate) -
                new Date(new Date(hdays.itemCreationDate).toDateString())
            ) / (1000 * 60 * 60 * 24).toFixed()
          )
        );
      });
    setHday(Math.max(...highest));
  };

  // const toggleButtonRef = useRef(null);
  // // const [dropdownOpen, setDropdownOpen] = useState(false);
  // const toggleButtonRefs = useRef([]);

  // const addToRefs = (el) => {
  //   if (el && !toggleButtonRefs.current.includes(el)) {
  //     toggleButtonRefs.current.push(el);
  //   }
  // };

  // const handleLinkClick = (e) => {
  //   // If the click originated from the dropdown toggle button, prevent the link navigation
  //   if (toggleButtonRef.current && toggleButtonRef.current.contains(e.target)) {
  //     e.preventDefault();
  //   }
  // };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <button
      type="button"
      className="btn folder-info"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        if (onClick) {
          onClick(e);
        }
        // onClick(e);
        getAllFolders();
        setShow(true);
      }}
    >
      {children}
    </button>
  ));

  const renderTooltip = (props) => (
    <Tooltip id="sp-btn-tooltip" className="tooltip" {...props}>
      Save Product
    </Tooltip>
  );

  localStorage.setItem("currentpage", currentPage);
  // if (currentPosts.length > 0) currentPosts.sort((a, b) => b.date - a.date);
  // if (Product_details.sort === "endingSoonest" && currentPosts.length > 0)
  //   currentPosts.reverse();
  return (
    <>
      <div className="row">
        <div className="col-12 text-center">
          <div>
            <h1 className="home_heading cstmHomeHeading">
              {Product_name === undefined ? bar_code : Product_name} ={" "}
              {Product_details.response.response &&
              Product_details.response.response.data.data.total > 0
                ? `${Product_details.response.response.data.data.total.toLocaleString(
                    "en-US"
                  )}`
                : "0"}{" "}
              Listings
            </h1>
          </div>
          <div className="mobile-nav text-start flex-res">
            <Link to="/home" className="pro-title">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14.2"
                height="24.1"
                viewBox="0 0 14.2 24.1"
              >
                <path
                  id="chevron_left_FILL0_wght400_GRAD0_opsz48"
                  d="M28.05,36,16,23.95,28.05,11.9l2.15,2.15-9.9,9.9,9.9,9.9Z"
                  transform="translate(-16 -11.9)"
                />
              </svg>
              {Product_name === undefined ? bar_code : Product_name}
            </Link>
            <a>
              {" "}
              ={" "}
              {Product_details.response.response &&
              Product_details.response.response.data.data.total > 0
                ? `${Product_details.response.response.data.data.total.toLocaleString(
                    "en-US"
                  )}`
                : "0"}{" "}
              Listings
            </a>
          </div>
        </div>
      </div>
      <div className="border-0">
        <div className="nav">
          <div className="pagination-wrapper" id="pagination-top">
            <nav>
              <ul className="pagination mb-0">
                <li className="page-item">
                  <a
                    className="page-link page-pre"
                    disabled={currentPage === 1 ? true : false}
                    onClick={() => {
                      handleprevious();
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="6.284"
                      height="10.771"
                      viewBox="0 0 6.284 10.771"
                    >
                      <path
                        id="arrow-left"
                        d="M5.335,4.26,9.368.224a.759.759,0,0,1,1.077,0,.769.769,0,0,1,0,1.08L5.875,5.877A.761.761,0,0,1,4.824,5.9L.222,1.307A.762.762,0,0,1,1.3.227Z"
                        transform="translate(6.284 0.106) rotate(91)"
                        fill="#4ea459"
                      />
                    </svg>
                    Prev
                  </a>
                </li>
                {renderpagination}
                <li className="page-item">
                  <a
                    className="page-link page-next"
                    onClick={() => {
                      handlenext();
                    }}
                  >
                    Next
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="6.284"
                      height="10.771"
                      viewBox="0 0 6.284 10.771"
                    >
                      <path
                        id="arrow-right"
                        d="M5.335,1.839,9.368,5.875a.759.759,0,0,0,1.077,0,.769.769,0,0,0,0-1.08L5.875.222A.761.761,0,0,0,4.824.2L.222,4.792A.762.762,0,1,0,1.3,5.872Z"
                        transform="matrix(0.017, 1, -1, 0.017, 6.098, 0)"
                        fill="#4ea459"
                      />
                    </svg>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className="tab-wrapper mt-md-5">
        <div className="row">
          <div className="col-12">
            <ul
              className="nav nav-pills mb-3 cstmNavPills"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  {Product_details.response.response &&
                  Product_details.response.response.data.data.total > 0
                    ? Product_details.filter !== 3
                      ? `Listings (1-
                                                         ${product.length.toLocaleString(
                                                           "en-US"
                                                         )})`
                      : `Listings (1-${paidCount})`
                    : "Listings (0)"}
                </button>
              </li>
              <li>
                <button
                  className="addBTN nav-link"
                  onClick={() => {
                    handlepagination();
                    setPaidCount(
                      nexturl !== undefined ? paidCount + 200 : paidCount
                    );
                  }}
                >
                  + Add More
                </button>
              </li>

              <li className="ms-auto">
                <Dropdown className="dropdown">
                  <DropdownToggle
                    className="btn btn-toggle dropdown-toggle"
                    variant=""
                  >{`Item/Page ${postsperpage}`}</DropdownToggle>
                  <DropdownMenu className="dropdown-menu">
                    <DropdownItem
                      className="dropdown-item"
                      onClick={() => {
                        setCurrentPage(1);
                        setMinPagenumberlimit(0);
                        setMaxPagenumberlimit(5);
                        dispatch(perPageData(10));
                      }}
                    >
                      10
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown-item"
                      onClick={() => {
                        setCurrentPage(1);
                        setMinPagenumberlimit(0);
                        setMaxPagenumberlimit(5);
                        dispatch(perPageData(20));
                      }}
                    >
                      20
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown-item"
                      onClick={() => {
                        setCurrentPage(1);
                        setMinPagenumberlimit(0);
                        setMaxPagenumberlimit(5);
                        dispatch(perPageData(50));
                      }}
                    >
                      50
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown-item"
                      onClick={() => {
                        setCurrentPage(1);
                        setMinPagenumberlimit(0);
                        setMaxPagenumberlimit(5);
                        dispatch(perPageData(100));
                      }}
                    >
                      100
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </li>
            </ul>
          </div>
        </div>
        <div className="tab-content " id="pills-tabContent">
          {!isloading ? (
            <div
              className="tab-pane fade show active active-listing"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
              tabIndex="0"
            >
              <div className="dataListingBox">
                <ul>
                  <li>
                    <span className="cstmTextContent textBlue">
                      ${lcost === Infinity ? 0 : lcost.toFixed(2)}
                    </span>
                    <span className="cstmTextContent">LOW</span>
                    <span className="cstmTextContent textBlue">
                      {lday === Infinity ? 0 : lday.toFixed()} Day
                    </span>
                  </li>
                  <li>
                    <span className="cstmTextContent textGreen">
                      ${medianPrice.toFixed(2)}
                    </span>
                    <span className="cstmTextContent">MID</span>
                    <span className="cstmTextContent textGreen">
                      {medianDays && medianDays.toFixed()} Days
                    </span>
                  </li>
                  <li>
                    <span className="cstmTextContent textRed">
                      ${hcost === -Infinity ? 0 : hcost.toFixed(2)}
                    </span>
                    <span className="cstmTextContent">HIGH</span>
                    <span className="cstmTextContent textRed">
                      {hday === -Infinity ? 0 : hday.toFixed()} Days
                    </span>
                  </li>
                  <li>
                    <span className="cstmTextContent">
                      ${tcost !== 0 ? (tcost / product.length).toFixed(2) : 0}
                    </span>
                    <span className="cstmTextContent">AVG</span>
                    <span className="cstmTextContent textGreen date-product">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="36"
                        height="41.95"
                        viewBox="0 0 36 41.95"
                      >
                        <path
                          id="timer_FILL0_wght400_GRAD0_opsz48"
                          d="M18,5V2H30V5Zm4.5,22.35h3V15.85h-3ZM24,43.95a17.292,17.292,0,0,1-6.975-1.425,18.267,18.267,0,0,1-9.6-9.6,17.783,17.783,0,0,1,0-13.95,18.267,18.267,0,0,1,9.6-9.6A17.292,17.292,0,0,1,24,7.95a17.53,17.53,0,0,1,6.3,1.125A17.346,17.346,0,0,1,35.55,12.2L38.1,9.65l2.1,2.1L37.65,14.3a18.437,18.437,0,0,1,3.075,4.85A16.561,16.561,0,0,1,42,25.95a17.292,17.292,0,0,1-1.425,6.975,18.267,18.267,0,0,1-9.6,9.6A17.292,17.292,0,0,1,24,43.95Zm0-3a14.465,14.465,0,0,0,10.625-4.375A14.465,14.465,0,0,0,39,25.95a14.465,14.465,0,0,0-4.375-10.625A14.465,14.465,0,0,0,24,10.95a14.465,14.465,0,0,0-10.625,4.375A14.465,14.465,0,0,0,9,25.95a14.465,14.465,0,0,0,4.375,10.625A14.465,14.465,0,0,0,24,40.95ZM24,26Z"
                          transform="translate(-6 -2)"
                        />
                      </svg>
                      {ndays !== 0 ? (ndays / product.length).toFixed(2) : 0}
                    </span>
                  </li>
                </ul>
              </div>
              <div className="row spinnerrow">
                <div className="d-flex justify-content-between">
                  <div className="d-sort mb-4">
                    <Sortfiltericon />
                    <Filter />
                    <Condition />
                  </div>
                </div>
                {Product_details.response.response !== undefined &&
                Product_details.response.response.data.data.total > 0 &&
                Product_details.response.response.data.data.itemSummaries
                  .length > 0 &&
                currentPosts !== undefined ? (
                  currentPosts.map((details, index) => {
                    return (
                      <div className="col-xl-3 col-lg-4 col-md-6 col-6 card-width">
                        {/* card start */}
                        <div
                          // to={`/product-details/${details.itemId}`}
                          className="product-details custom-card-wrapper"
                          // onClick={handleLinkClick}
                        >
                          <div className="custom-card">
                            <div className="product-img-wrapper">
                              <img
                                src={
                                  details.hasOwnProperty("thumbnailImages")
                                    ? details.thumbnailImages[0].imageUrl
                                    : `${process.env.PUBLIC_URL}/Images/default.jpg`
                                }
                                alt="product"
                                className="img-fluid"
                              />
                              <div className="date-str-info">
                                <span className="date-product">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="36"
                                    height="41.95"
                                    viewBox="0 0 36 41.95"
                                  >
                                    <path
                                      id="timer_FILL0_wght400_GRAD0_opsz48"
                                      d="M18,5V2H30V5Zm4.5,22.35h3V15.85h-3ZM24,43.95a17.292,17.292,0,0,1-6.975-1.425,18.267,18.267,0,0,1-9.6-9.6,17.783,17.783,0,0,1,0-13.95,18.267,18.267,0,0,1,9.6-9.6A17.292,17.292,0,0,1,24,7.95a17.53,17.53,0,0,1,6.3,1.125A17.346,17.346,0,0,1,35.55,12.2L38.1,9.65l2.1,2.1L37.65,14.3a18.437,18.437,0,0,1,3.075,4.85A16.561,16.561,0,0,1,42,25.95a17.292,17.292,0,0,1-1.425,6.975,18.267,18.267,0,0,1-9.6,9.6A17.292,17.292,0,0,1,24,43.95Zm0-3a14.465,14.465,0,0,0,10.625-4.375A14.465,14.465,0,0,0,39,25.95a14.465,14.465,0,0,0-4.375-10.625A14.465,14.465,0,0,0,24,10.95a14.465,14.465,0,0,0-10.625,4.375A14.465,14.465,0,0,0,9,25.95a14.465,14.465,0,0,0,4.375,10.625A14.465,14.465,0,0,0,24,40.95ZM24,26Z"
                                      transform="translate(-6 -2)"
                                    />
                                  </svg>
                                  {(
                                    (new Date(new Date(endDate)) -
                                      new Date(
                                        new Date(
                                          details.itemCreationDate
                                        ).toDateString()
                                      )) /
                                    (1000 * 60 * 60 * 24)
                                  ).toFixed() == 0
                                    ? "Today"
                                    : `${(
                                        (new Date(new Date(endDate)) -
                                          new Date(
                                            new Date(
                                              details.itemCreationDate
                                            ).toDateString()
                                          )) /
                                        (1000 * 60 * 60 * 24)
                                      ).toFixed()}`}
                                </span>
                                {/* <div className=""> */}
                                <Dropdown
                                  variant=""
                                  drop="end"
                                  className=""
                                  // show={dropdownOpen}
                                  // onToggle={(isOpen) => setDropdownOpen(isOpen)}
                                >
                                  <OverlayTrigger
                                    placement="right"
                                    overlay={renderTooltip}
                                  >
                                    <DropdownToggle
                                      variant=""
                                      className="my-btn no-caret folder-info"
                                      // ref={addToRefs}
                                      // as={CustomToggle}
                                    >
                                      <span className="date-product">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="44"
                                          height="31.95"
                                          viewBox="0 0 576 512"
                                        >
                                          <path
                                            id="local_shipping_FILL0_wght400_GRAD0_opsz48"
                                            d="M384 480h48c11.4 0 21.9-6 27.6-15.9l112-192c5.8-9.9 5.8-22.1 .1-32.1S555.5 224 544 224H144c-11.4 0-21.9 6-27.6 15.9L48 357.1V96c0-8.8 7.2-16 16-16H181.5c4.2 0 8.3 1.7 11.3 4.7l26.5 26.5c21 21 49.5 32.8 79.2 32.8H416c8.8 0 16 7.2 16 16v32h48V160c0-35.3-28.7-64-64-64H298.5c-17 0-33.3-6.7-45.3-18.7L226.7 50.7c-12-12-28.3-18.7-45.3-18.7H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H87.7 384z"
                                            transform="translate(-2 -8)"
                                          />
                                        </svg>
                                      </span>
                                    </DropdownToggle>
                                  </OverlayTrigger>

                                  <DropdownMenu>
                                    {folderNames.length > 0 ? (
                                      folderNames.map((fdetail) => {
                                        return (
                                          <DropdownItem
                                            className="dropdown-item"
                                            onClick={(e) => {
                                              saveProduct(fdetail._id, details);
                                              e.preventDefault();
                                            }}
                                          >
                                            {fdetail.folderName}
                                          </DropdownItem>
                                        );
                                      })
                                    ) : (
                                      <DropdownItem className="dropdown-item">
                                        {" "}
                                        {show
                                          ? "Loading ..."
                                          : "Folders not found!"}
                                      </DropdownItem>
                                    )}
                                  </DropdownMenu>
                                </Dropdown>
                              </div>
                            </div>
                            <Link
                              to={`/product-details/${details.itemId}`}
                              className="custom-card-wrapper text-primary"
                              // onClick={handleLinkClick}
                            >
                              <div className="card_content">
                                <h3 className="product-heading mt-2">
                                  <strong>
                                    <small>{details.title}</small>
                                  </strong>
                                </h3>
                                <div className="product-info">
                                  <span className="shipping-price">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="21.966"
                                      height="16.248"
                                      viewBox="0 0 21.966 16.248"
                                    >
                                      <path
                                        id="Icon_material-local-shipping"
                                        data-name="Icon material-local-shipping"
                                        d="M19.607,9.812H16.748V6H3.406A1.912,1.912,0,0,0,1.5,7.906V18.389H3.406a2.859,2.859,0,0,0,5.718,0h5.718a2.859,2.859,0,1,0,5.718,0h1.906V13.624ZM6.265,19.819a1.43,1.43,0,1,1,1.43-1.43A1.428,1.428,0,0,1,6.265,19.819Zm12.866-8.577L21,13.624h-4.25V11.242ZM17.7,19.819a1.43,1.43,0,1,1,1.43-1.43A1.428,1.428,0,0,1,17.7,19.819Z"
                                        transform="translate(-1 -5.5)"
                                        fill="none"
                                        stroke="#000"
                                        stroke-width="1"
                                      />
                                    </svg>

                                    {details.shippingOptions !== undefined
                                      ? details.shippingOptions.map((e) => {
                                          if (
                                            e.shippingCostType ===
                                              "CALCULATED" ||
                                            e.shippingCost.value === null
                                          ) {
                                            return "Paid";
                                          }

                                          if (
                                            parseFloat(e.shippingCost.value) ===
                                            0.0
                                          ) {
                                            return "Free";
                                          }
                                          return "Paid";
                                          {
                                            /* return e.shippingCost !== undefined
                                          ? e.shippingCost.value == 0
                                            ? "free"
                                            : `$${parseFloat(
                                                e.shippingCost.value
                                              ).toLocaleString("en-US")}`
                                          : "free"; */
                                          }
                                        })
                                      : "free"}
                                  </span>
                                  <span className="product-cost">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="19"
                                      height="18.995"
                                      viewBox="0 0 19 18.995"
                                    >
                                      <path
                                        id="Icon_ionic-md-pricetag"
                                        data-name="Icon ionic-md-pricetag"
                                        d="M21,4.5H13.574L5.086,13.293a1.99,1.99,0,0,0,0,2.822l5.8,5.794a2.152,2.152,0,0,0,1.35.586,2.351,2.351,0,0,0,1.472-.586L22.5,13.5V6ZM19.56,9.314a1.5,1.5,0,1,1,1-1A1.459,1.459,0,0,1,19.56,9.314Z"
                                        transform="translate(-3.999 -4)"
                                        fill="none"
                                        stroke="#4ea459"
                                        stroke-width="1"
                                      />
                                    </svg>
                                    <small>
                                      $
                                      {details.price &&
                                        parseFloat(
                                          details.price.value
                                        ).toLocaleString("en-US")}
                                    </small>
                                  </span>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <h2 style={{ textAlign: "center" }}>
                    {Product_details.filter === 3 ? (
                      Product_details.response.response !== undefined &&
                      Product_details.response.response.data.data.itemSummaries
                        .length !== 0 ? (
                        Product_details.response.response !== undefined ? (
                          Product_details.response.response.data.data.total ===
                            0 &&
                          !Product_details.response.response.data.data
                            .loading ? (
                            <Loader />
                          ) : Product_details.response.response.data.data
                              .status === 400 ? (
                            "Something went wrong"
                          ) : (
                            "Products not found"
                          )
                        ) : (
                          <Loader />
                        )
                      ) : (
                        "Products not found"
                      )
                    ) : Product_details.response.response !== undefined ? (
                      Product_details.response.response.data.data.total === 0 &&
                      !Product_details.response.response.data.data.loading ? (
                        <Loader />
                      ) : Product_details.response.response.data.data.status ===
                        400 ? (
                        "Something went wrong"
                      ) : (
                        "Products not found"
                      )
                    ) : (
                      <Loader />
                    )}
                  </h2>
                )}
                <div className="px-3 border-0">
                  <div className="nav">
                    <div className="pagination-wrapper">
                      <nav>
                        <ul className="pagination">
                          <li className="page-item">
                            <a
                              className="page-link page-pre"
                              disabled={currentPage === 1 ? true : false}
                              onClick={() => {
                                handleprevious();
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="6.284"
                                height="10.771"
                                viewBox="0 0 6.284 10.771"
                              >
                                <path
                                  id="arrow-left"
                                  d="M5.335,4.26,9.368.224a.759.759,0,0,1,1.077,0,.769.769,0,0,1,0,1.08L5.875,5.877A.761.761,0,0,1,4.824,5.9L.222,1.307A.762.762,0,0,1,1.3.227Z"
                                  transform="translate(6.284 0.106) rotate(91)"
                                  fill="#4ea459"
                                />
                              </svg>
                              Prev
                            </a>
                          </li>
                          {renderpagination}
                          <li className="page-item">
                            <a
                              className="page-link page-next"
                              onClick={() => {
                                handlenext();
                              }}
                            >
                              Next
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="6.284"
                                height="10.771"
                                viewBox="0 0 6.284 10.771"
                              >
                                <path
                                  id="arrow-right"
                                  d="M5.335,1.839,9.368,5.875a.759.759,0,0,0,1.077,0,.769.769,0,0,0,0-1.08L5.875.222A.761.761,0,0,0,4.824.2L.222,4.792A.762.762,0,1,0,1.3,5.872Z"
                                  transform="matrix(0.017, 1, -1, 0.017, 6.098, 0)"
                                  fill="#4ea459"
                                />
                              </svg>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Loader />
          )}
          <ToastContainer />
        </div>
      </div>
    </>
  );
};
export default Activelist;
