import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Mainheader from "../components/mainheader";
import moment from "moment";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useParams, Link } from "react-router-dom";
import Loader from "../components/loader";
import {
  pageId,
  getproductbykeyword,
  backButton,
  sortApiDataByPrice,
  conditionMessage,
  filterMessage,
} from "../redux/actions/users";
import { checkSubscription } from "../helpers/common";

const Mycomp = () => {
  const dispatch = useDispatch();
  let { Product_name } = useParams();
  const [comphistorydata, setcomphistorydata] = useState("");
  const [isloading, setIsloading] = useState(false);
  const pageRange = useSelector((state) => state.getpageId);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsperpage, setPostsperpage] = useState(10);
  const [pagenumberlimit, setPagenumberlimit] = useState(5);
  const [maxpagenumberlimit, setMaxPagenumberlimit] = useState(10);
  const [minpagenumberlimit, setMinPagenumberlimit] = useState(0);
  const Product_details = useSelector(
    (state) => state.getproductbykeywordReducer
  );

  //get comhistory details
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_BASE_URI}/getcomphistory`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setcomphistorydata(res.data.data);
        setIsloading(true);
      });
  }, []);

  useEffect(() => {
    checkSubscription();
  });

  //functionality to calculate page numbers according to api data.
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(comphistorydata.length / postsperpage); i++) {
    pageNumbers.push(i);
  }
  const indexofLastPost = currentPage * postsperpage;
  const indexofFirstPost = indexofLastPost - postsperpage;
  const currentPosts =
    comphistorydata.length > 0
      ? comphistorydata.slice(indexofFirstPost, indexofLastPost)
      : "";
  const paginate = (page) => {
    setCurrentPage(page);
  };

  //fucntionality to render the pagenumbers
  const renderpagination = pageNumbers.map((number) => {
    if (number < maxpagenumberlimit + 1 && number > minpagenumberlimit) {
      return (
        <li
          key={number}
          className={number === currentPage ? "page-item active" : "page-item"}
        >
          <a
            onClick={() => {
              paginate(number);
            }}
            className="page-link  "
          >
            {number}
          </a>
        </li>
      );
    } else {
      return null;
    }
  });

  const handlenext = () => {
    if (currentPage < pageNumbers.length) {
      setCurrentPage(currentPage + 1);
      if (currentPage + 1 > maxpagenumberlimit) {
        setMaxPagenumberlimit(maxpagenumberlimit + pagenumberlimit);
        setMinPagenumberlimit(minpagenumberlimit + pagenumberlimit);
      }
    }
  };

  const handleprevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      if ((currentPage - 1) % pagenumberlimit == 0) {
        setMaxPagenumberlimit(maxpagenumberlimit - pagenumberlimit);
        setMinPagenumberlimit(minpagenumberlimit - pagenumberlimit);
      }
    }
  };

  return (
    <>
      <Mainheader />
      <div className="container">
        <div className="mt-5">
          <main className="">
            <div className="row comp-card">
              {isloading ? (
                comphistorydata && comphistorydata.length > 0 ? (
                  currentPosts.map((compdetails, index) => {
                    return (
                      <div className=" col-md-12">
                        <div className="card" id="accordionExample">
                          <div className="card-body">
                            <Link
                              to={`/searchlist/${compdetails.keyword}`}
                              onClick={() => {
                                dispatch(sortApiDataByPrice(null));
                                Product_details.condition = "";
                                Product_details.filter = 1;
                                Product_details.pagination.offset = 0;
                                dispatch(filterMessage("both"));
                                dispatch(conditionMessage("none"));
                                dispatch(pageId(1));
                                dispatch(backButton(true));
                              }}
                              className="custom-card-wrapper"
                            >
                              <span
                                className="accordion-header"
                                id="headingOne"
                              >
                                {compdetails.keyword}{" "}
                                {moment(compdetails.updatedAt).format("ll")}
                              </span>
                              <span className="input_search_icon input_search_icon_refresh">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="44"
                                  height="31.95"
                                  viewBox="0 0 576 512"
                                >
                                  <path
                                    id="local_shipping_FILL0_wght400_GRAD0_opsz48"
                                    d="M386.3 160H336c-17.7 0-32 14.3-32 32s14.3 32 32 32H464c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32s-32 14.3-32 32v51.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0s-87.5 229.3 0 316.8s229.3 87.5 316.8 0c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0c-62.5 62.5-163.8 62.5-226.3 0s-62.5-163.8 0-226.3s163.8-62.5 226.3 0L386.3 160z"
                                    transform="translate(-2 -8)"
                                  />
                                </svg>
                              </span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <h2 style={{ textAlign: "center" }}>No record found</h2>
                )
              ) : (
                <Loader />
              )}
            </div>
          </main>
        </div>
        <div className="border-0 d-flex flex-column align-items-center justify-content-between card-footer">
          <div className="nav">
            <div className="custom-nav pagination-wrapper">
              <nav>
                <ul className="pagination">
                  <li className="page-item">
                    <a
                      className="page-link page-pre"
                      disabled={currentPage === 1 ? true : false}
                      onClick={() => {
                        handleprevious();
                      }}
                    >
                      Prev
                    </a>
                  </li>
                  {renderpagination}
                  <li className="page-item">
                    <a
                      className="page-link page-next"
                      onClick={() => {
                        handlenext();
                      }}
                    >
                      Next
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Mycomp;
