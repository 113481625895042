import axios from "axios";

const apiUrl = process.env.REACT_APP_SERVER_BASE_URI;

//get Active listing items from keyword and barcode api
export let getproducts = async (
  value,
  sort,
  pagination,
  priceRange,
  apiType,
  filter,
  condition
) => {
  let ApiRoute = "getItemByKeyword?items_name";

  if (apiType === "productSearchByBarCode") {
    ApiRoute = "getActiveListingByBarcode?barcodeNumber";
  }
  if (apiType === "productSearchByText") {
    ApiRoute = "getItemByKeyword?items_name";
  }

  return await axios
    .get(
      `${apiUrl}/${ApiRoute}=${value}&offset=${pagination.offset}&limit=${
        pagination.limit
      }&sort=${
        sort == null ? "" : sort
      }&filter=${filter}&condition=${condition}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-EBAY-C-MARKETPLACE-ID": "EBAY_US",
        },
      }
    )
    .then((response) => {
      response.data.data.loading = true;
      if (filter === 3) {
        let paiddata = [];
        const SortPaid = response.data.data.itemSummaries.map(
          (paidShipping) => {
            return (
              paidShipping.shippingOptions !== undefined &&
              paidShipping.shippingOptions.map((e) => {
                if (e.shippingCost !== undefined) {
                  if (parseFloat(e.shippingCost.value).toFixed() > 0) {
                    return paiddata.push(paidShipping);
                  }
                }
              })
            );
          }
        );
        return {
          data: {
            data: {
              itemSummaries: paiddata,
              limit: 200,
              loading: true,
              next: `${response.data.data.next}`,
              prev: response.data.data.prev,
              offset: response.data.data.offset,
              total: response.data.data.total,
            },
          },
        };
        //
      } else {
        return response;
      }
    })
    .catch((error) => {
      throw error;
    });
};

// get sold listing by keyword APi
export let getsoldproducts = async (value, sort, pagination, priceRange) => {
  return await axios
    .get(
      `${apiUrl}/getAllSoldList?q=${value}&offset=${pagination.offset}&limit=${
        pagination.limit
      }&sort=${sort ? "price" : "-price"}&filterMinRange=${
        priceRange.currentMinVal
      }&filterMaxRange=${priceRange.currentMaxVal}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-EBAY-C-MARKETPLACE-ID": "EBAY_US",
        },
      }
    )
    .then((response) => {
      response.data.data.loading = true;
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
