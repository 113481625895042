import React, { useEffect, useState, useRef } from "react";
import StripeCheckout from "react-stripe-checkout";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import TermsCondition from "../components/termsCondition";
import PrivacyPolicy from "../pages/privacyPolicy";
import {
  cancelSubscribed,
  getproductbykeyword,
  subscriptionType,
} from "../redux/actions/users";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../components/loader";
import { Button } from "bootstrap";

const CheckoutForm = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [payNow, setPayNow] = useState(true);
  const [subMonthlyPrice, setSubMonthlyPrice] = useState();
  const [subYearlyPrice, setSubYearlyPrice] = useState();
  const [couponCode, setCouponCode] = useState("");
  const [coupon, setCoupon] = useState({});
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [price, setPrice] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [userID, setUserID] = useState(null);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [isTermsOpen, setIsTermsOpen] = useState(false);
  const [isPrivacyOpen, setPrivacyOpen] = useState(false);
  const [show, setShow] = useState(true);
  const [days, setDays] = useState(null);
  const [couponApplied, setCouponApplied] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const navigate = useNavigate();
  const [stripeToken, setStripeToken] = useState("");
  const dispatch = useDispatch();
  const stripeCheckoutRef = useRef(null);
  const stripeKey = process.env.REACT_APP_STRIPE_LIVE_KEY;
  const [trialDays, setTrialDays] = useState(7);
  let subStart = new Date();
  subStart.setDate(subStart.getDate() + trialDays);
  const [subType, setSubType] = useState("monthly");
  const [monthlyOption, setMonthlyOption] = useState({});
  const [yearlyOption, setYearlyOption] = useState({});
  const [trialBump, setTrialBump] = useState(false);

  const handlelogout = () => {
    localStorage.clear();
    navigate("/");
  };

  // sucribe user to service
  const subscribeUser = async (token, adresses) => {
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_BASE_URI}/subscribeUser`,
        {
          userEmail: userEmail,
          userID: userID,
          subChoice: subType === "monthly" ? monthlyOption : yearlyOption,
          stripeToken: token,
          coupon: couponApplied ? coupon.id : "",
          trial: trialDays,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.status == 200) {
          setIsloading(false);
          toast.success("Subscription started successfully");
          localStorage.setItem("subStatus", res.data.subStatus);
          setTimeout(() => {
            navigate("/home");
          }, 1000);
        }

        if (res.status == 400) {
          setIsloading(false);
          toast.success("Error Subscribing User");
          console.log(res.data);
          setTimeout(() => {
            navigate("/checkout");
          }, 1000);
          setCoupon("");
          setCouponApplied(false);
        }
      })
      .catch((error) => console.log(`subUser function error: ${error}`));
  };

  // stripe token handler
  const Subscription = async (token, addresses) => {
    setIsloading(true);
    subscribeUser(token);
  };

  //gets current subscription price options
  useEffect(() => {
    setUserEmail(localStorage.getItem("email"));
    setUserID(localStorage.getItem("userID"));
    axios
      .get(`${process.env.REACT_APP_SERVER_BASE_URI}/getPrices`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        const subPrices = res.data.prices;
        // setPriceOptions(res.data.prices);
        subPrices.map((option) => {
          if (option.lookup_key == "reseller_monthly") {
            const monthlyPrice = {
              subType: "monthly",
              productID: option.product,
              priceID: option.id,
              price: option.unit_amount,
            };
            setSubMonthlyPrice(monthlyPrice.price);
            setMonthlyOption(monthlyPrice);
          }

          if (option.lookup_key == "reseller_yearly") {
            const yearlyPrice = {
              subType: "yearly",
              productID: option.product,
              priceID: option.id,
              price: option.unit_amount,
            };
            setSubYearlyPrice(yearlyPrice.price);
            setYearlyOption(yearlyPrice);
          }
        });
      })
      .catch((err) => {});
  }, []);

  //Discount coupon API
  const Discount = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_BASE_URI}/applyCoupons`,
        {
          couponCode: couponCode,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        const status = res.status;
        const data = res.data;
        if (status == 200) {
          if (data === "invalid coupon code") {
            toast.error("Please enter a valid discount code");
          } else {
            const couponData = data.coupon.coupon;
            setCoupon(couponData);
            if (couponData.percent_off !== null) {
              const discount =
                (monthlyOption.price * couponData.percent_off) / 100;
              setSubMonthlyPrice(monthlyOption.price - discount);
              toast.success("Discount code applied successfully");
              setCouponApplied(true);
            }

            if (couponData.amount_off !== null) {
              setSubMonthlyPrice(monthlyOption.price - couponData.amount_off);
              toast.success("Discount code applied successfully");
              setCouponApplied(true);
            }
          }
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  // discount code notice popup
  const DiscountNote = () => {
    return alert(
      "Please note: Your card will not be charged anything during your Free Trial. You will receive an email detailing your first billing date with other important account information. Thank you!"
    );
  };

  const handleCheckoutAlert = () => {
    if (couponApplied) {
      DiscountNote();
    }

    if (stripeCheckoutRef.current) {
      stripeCheckoutRef.current.click();
    }
  };

  const showtermsModal = () => {
    setIsTermsOpen(true);
  };

  const hidetermsModal = () => {
    setIsTermsOpen(false);
  };

  const showprivacyModal = () => {
    setPrivacyOpen(true);
  };

  const hideprivacyModal = () => {
    setPrivacyOpen(false);
  };

  const acceptCondition = () => {
    setIsSubscribed(true);
    setIsOpen(false);
  };

  const cancelCondition = () => {
    setIsSubscribed(false);
    setIsOpen(false);
  };

  const subCheck = () => {
    if (subType === "monthly") {
      return subMonthlyPrice;
    }

    if (subType === "yearly") {
      return subYearlyPrice;
    }
  };

  return (
    <>
      <header>
        <nav className="navbar navbar-light home_navigation">
          <div className="container">
            <Link className="navbar-brand" to="#">
              <img
                src={`${process.env.PUBLIC_URL}/Images/Logo.png`}
                alt="logo"
              />
            </Link>
            <div className="d-flex secondary_nav_wrapper">
              <div className="mobile-header-heading"></div>
            </div>
            <div className="dropdown_wrapper">
              <button
                onClick={() => setShow(!show)}
                className="btn custom_toggle_btn"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRight"
                aria-controls="offcanvasRight"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="36.4"
                  height="36.6"
                  viewBox="0 0 36.4 36.6"
                >
                  <path
                    id="tune_FILL0_wght500_GRAD0_opsz48"
                    d="M21.3,42.3V30.75h3.25V34.9H42.2v3.3H24.55v4.1ZM5.8,38.2V34.9H18.4v3.3Zm9.35-8.45v-4.1H5.8v-3.3h9.35v-4.2H18.4v11.6Zm6.15-4.1v-3.3H42.2v3.3Zm8.3-8.4V5.7h3.25V9.8H42.2v3.3H32.85v4.15ZM5.8,13.1V9.8H26.7v3.3Z"
                    transform="translate(-5.8 -5.7)"
                  />
                </svg>
              </button>

              <div className={show ? "custom_dropdown" : "custom_dropdown1"}>
                <button
                  className="close_btn btn"
                  onClick={() => setShow(!show)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                  >
                    <path
                      id="Icon_material-close"
                      data-name="Icon material-close"
                      d="M28.5,9.615,26.385,7.5,18,15.885,9.615,7.5,7.5,9.615,15.885,18,7.5,26.385,9.615,28.5,18,20.115,26.385,28.5,28.5,26.385,20.115,18Z"
                      transform="translate(-7.5 -7.5)"
                    />
                  </svg>
                </button>
                <div className="custom_dropdown_nav ms-5">
                  <a href="https://myresellerapp.com" target="_blank">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                      >
                        <path
                          id="info_FILL0_wght400_GRAD0_opsz48"
                          d="M22.65,34h3V22h-3ZM24,18.3a1.643,1.643,0,0,0,1.175-.45,1.515,1.515,0,0,0,.475-1.15,1.681,1.681,0,0,0-.475-1.2,1.631,1.631,0,0,0-2.35,0,1.681,1.681,0,0,0-.475,1.2,1.515,1.515,0,0,0,.475,1.15A1.643,1.643,0,0,0,24,18.3ZM24,44a19.352,19.352,0,0,1-7.75-1.575A20.15,20.15,0,0,1,5.575,31.75a19.978,19.978,0,0,1,0-15.55,19.988,19.988,0,0,1,4.3-6.35A20.5,20.5,0,0,1,16.25,5.575a19.978,19.978,0,0,1,15.55,0A19.969,19.969,0,0,1,42.425,16.2a19.978,19.978,0,0,1,0,15.55,20.5,20.5,0,0,1-4.275,6.375,19.988,19.988,0,0,1-6.35,4.3A19.475,19.475,0,0,1,24,44Zm.05-3a16.3,16.3,0,0,0,12-4.975A16.483,16.483,0,0,0,41,23.95a16.342,16.342,0,0,0-4.95-12A16.4,16.4,0,0,0,24,7a16.424,16.424,0,0,0-12.025,4.95A16.359,16.359,0,0,0,7,24a16.383,16.383,0,0,0,4.975,12.025A16.441,16.441,0,0,0,24.05,41ZM24,24Z"
                          transform="translate(-4 -4)"
                        />
                      </svg>
                    </span>
                    About ReSeller
                  </a>
                  <Link
                    onClick={() => {
                      showprivacyModal();
                      setShow(!show);
                    }}
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="40"
                        viewBox="0 0 32 40"
                      >
                        <path
                          id="verified_user_FILL0_wght400_GRAD0_opsz48"
                          d="M21.8,30.65l11.4-11.4L31.1,17.2l-9.15,9.15L16.9,21.3l-2.2,2.2ZM24,43.95a19.635,19.635,0,0,1-11.5-8.125A23.715,23.715,0,0,1,8,21.85V9.95l16-6,16,6v11.9a23.715,23.715,0,0,1-4.5,13.975A19.635,19.635,0,0,1,24,43.95Zm0-3.1a18.038,18.038,0,0,0,9.375-7.175A20.4,20.4,0,0,0,37,21.85v-9.8L24,7.15l-13,4.9v9.8a20.4,20.4,0,0,0,3.625,11.825A18.038,18.038,0,0,0,24,40.85ZM24,24Z"
                          transform="translate(-8 -3.95)"
                        />
                      </svg>
                    </span>
                    Privacy Policy
                  </Link>
                  <Link
                    onClick={() => {
                      showtermsModal();
                      setShow(!show);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="36"
                      height="40"
                      viewBox="0 0 36 40"
                    >
                      <path
                        id="assignment_turned_in_FILL0_wght400_GRAD0_opsz48"
                        d="M21.15,31.55,35,17.7l-2.15-2.15-11.7,11.7L15.1,21.2,13,23.3ZM9,42a2.988,2.988,0,0,1-3-3V9A2.988,2.988,0,0,1,9,6H19.25a4.437,4.437,0,0,1,1.6-2.875,4.972,4.972,0,0,1,6.3,0A4.437,4.437,0,0,1,28.75,6H39a2.988,2.988,0,0,1,3,3V39a2.988,2.988,0,0,1-3,3Zm0-3H39V9H9ZM24,8.15a1.679,1.679,0,0,0,1.225-.525,1.692,1.692,0,0,0,0-2.45,1.692,1.692,0,0,0-2.45,0,1.692,1.692,0,0,0,0,2.45A1.679,1.679,0,0,0,24,8.15ZM9,39v0Z"
                        transform="translate(-6 -2)"
                      />
                    </svg>
                    Terms of Use
                  </Link>
                  <button
                    className="my-btn"
                    onClick={(e) => {
                      handlelogout(e);
                    }}
                  >
                    <span className="me-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 36 36"
                      >
                        <path
                          id="logout_FILL0_wght400_GRAD0_opsz48"
                          d="M9,42a2.878,2.878,0,0,1-2.1-.9A2.878,2.878,0,0,1,6,39V9a2.878,2.878,0,0,1,.9-2.1A2.878,2.878,0,0,1,9,6H23.55V9H9V39H23.55v3Zm24.3-9.25L31.15,30.6l5.1-5.1H18.75v-3h17.4l-5.1-5.1,2.15-2.15,8.8,8.8Z"
                          transform="translate(-6 -6)"
                        />
                      </svg>
                    </span>
                    Log Out
                  </button>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <div
        className={show ? "custom_page_dropshadow" : "custom_page_dropshadow1"}
      ></div>
      <Modal
        className="modal fade modal-xl"
        show={isPrivacyOpen}
        id="privacymodal"
      >
        <Modal.Header>
          <h4>Reseller Privacy Policy</h4>
          <button
            type="button"
            className="close custom-close"
            onClick={hideprivacyModal}
            data-dismiss="modal"
          >
            <span>&times;</span>
          </button>
        </Modal.Header>
        <form>
          <Modal.Body>
            <PrivacyPolicy />
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              onClick={hideprivacyModal}
              className="btn btn-danger"
            >
              Cancel
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      {/* terms and condition modal */}
      <Modal
        className="modal fade modal-xl"
        id="termscondition"
        show={isTermsOpen}
      >
        <Modal.Header>
          <h4>ReSeller Terms of Use</h4>
          <button
            type="button"
            className="close custom-close"
            onClick={hidetermsModal}
            data-dismiss="modal"
          >
            <span>&times;</span>
          </button>
        </Modal.Header>
        <form>
          <Modal.Body>
            <TermsCondition />
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              onClick={hidetermsModal}
              className="btn btn-danger"
            >
              Cancel
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      <section className="mt-1">
        <div className="container">
          {!isloading ? (
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="checkout_Box checkout_Boxcstm1 p-1">
                  <span className="checkout_main_icon">
                    <img src={`${process.env.PUBLIC_URL}/Images/Logo.png`} />
                  </span>
                  <span className="checkout_cta fw-bold">
                    Please Make a Selection
                  </span>
                  <form className="checkout_Form_box">
                    {/* <div className="mb-3 check_customInput_Box">
                    <div className="input-wrapper">
                      <span className="checkout_search_icon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M492.4 220.8c-8.9 0-18.7 6.7-18.7 22.7h36.7c0-16-9.3-22.7-18-22.7zM375 223.4c-8.2 0-13.3 2.9-17 7l.2 52.8c3.5 3.7 8.5 6.7 16.8 6.7 13.1 0 21.9-14.3 21.9-33.4 0-18.6-9-33.2-21.9-33.1zM528 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h480c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zM122.2 281.1c0 25.6-20.3 40.1-49.9 40.3-12.2 0-25.6-2.4-38.8-8.1v-33.9c12 6.4 27.1 11.3 38.9 11.3 7.9 0 13.6-2.1 13.6-8.7 0-17-54-10.6-54-49.9 0-25.2 19.2-40.2 48-40.2 11.8 0 23.5 1.8 35.3 6.5v33.4c-10.8-5.8-24.5-9.1-35.3-9.1-7.5 0-12.1 2.2-12.1 7.7 0 16 54.3 8.4 54.3 50.7zm68.8-56.6h-27V275c0 20.9 22.5 14.4 27 12.6v28.9c-4.7 2.6-13.3 4.7-24.9 4.7-21.1 0-36.9-15.5-36.9-36.5l.2-113.9 34.7-7.4v30.8H191zm74 2.4c-4.5-1.5-18.7-3.6-27.1 7.4v84.4h-35.5V194.2h30.7l2.2 10.5c8.3-15.3 24.9-12.2 29.6-10.5h.1zm44.1 91.8h-35.7V194.2h35.7zm0-142.9l-35.7 7.6v-28.9l35.7-7.6zm74.1 145.5c-12.4 0-20-5.3-25.1-9l-.1 40.2-35.5 7.5V194.2h31.3l1.8 8.8c4.9-4.5 13.9-11.1 27.8-11.1 24.9 0 48.4 22.5 48.4 63.8 0 45.1-23.2 65.5-48.6 65.6zm160.4-51.5h-69.5c1.6 16.6 13.8 21.5 27.6 21.5 14.1 0 25.2-3 34.9-7.9V312c-9.7 5.3-22.4 9.2-39.4 9.2-34.6 0-58.8-21.7-58.8-64.5 0-36.2 20.5-64.9 54.3-64.9 33.7 0 51.3 28.7 51.3 65.1 0 3.5-.3 10.9-.4 12.9z" /></svg>
                      </span>
                      <span className="checkout_span2">Stripe payment</span>
                    </div>

                  </div> */}

                    <div style={{ letterSpacing: "-1px" }}>
                      <div className="sub_section">
                        <span className="check_terms">
                          <input
                            type="checkbox"
                            checked={subType == "monthly" ? true : false}
                            onClick={(e) => {
                              setSubType("monthly");
                              setSubMonthlyPrice(monthlyOption.price);
                            }}
                          />
                          &nbsp;&nbsp;
                          {`Monthly Subscription: $${monthlyOption.price /
                            100} per month + ${trialDays}
                          day free trial`}
                        </span>
                        <div className="d-flex align-items-start gap-2 sub_details">
                          <span className="checkout_span fw-bold">
                            $0 today
                          </span>
                          <span className="checkout_span1 sub_description">
                            {` - $${subMonthlyPrice /
                              100} charged on ${subStart.toLocaleDateString()}`}
                          </span>
                        </div>

                        {/* *********coupon input section********* */}
                        <div className="coupon-section">
                          <input
                            type="text"
                            className="Coupon_Input"
                            disabled={
                              couponApplied || subType === "yearly"
                                ? true
                                : false
                            }
                            placeholder="Enter discount code"
                            onChange={(e) => {
                              setCouponCode(e.target.value);
                            }}
                            value={couponCode}
                          ></input>
                          <button
                            type="button"
                            disabled={couponApplied || subType === "yearly"}
                            className="Coupon_btn w-25 mx-1"
                            onClick={() => {
                              Discount();
                            }}
                          >
                            {couponApplied ? "Applied" : "Apply"}
                          </button>
                          {subType === "monthly" ? (
                            <button
                              type="button"
                              disabled={!couponApplied}
                              hidden={couponApplied ? false : true}
                              onClick={() => {
                                setCouponCode("");
                                setCouponApplied(!couponApplied);
                                setSubMonthlyPrice(monthlyOption.price);
                                toast.error("Code has been removed");
                              }}
                              className="Coupon_btn1"
                            >
                              Cancel
                            </button>
                          ) : (
                            ""
                          )}
                        </div>

                        {/* <span className="reseller-or">
                        <center>OR</center>
                      </span> */}

                        <div className="trial_section">
                          <span className="check_terms">
                            <input
                              type="checkbox"
                              checked={subType == "yearly" ? true : false}
                              onClick={(e) => {
                                setSubType("yearly");
                                setCouponCode("");
                                setSubMonthlyPrice(monthlyOption.price);
                                setCouponApplied(false);
                              }}
                            />
                            &nbsp;&nbsp;
                            {`Yearly Subscription: $${yearlyOption.price /
                              100} per year + ${trialDays}
                          day free trial`}
                          </span>
                          <span className="checkout_span sub_details">
                            Thats 4 months FREE
                          </span>
                          <div className="d-flex align-items-start gap-2 sub_details">
                            <span className="checkout_span fw-bold">
                              {`$0 today`}
                            </span>
                            <span className="checkout_span1">
                              {`- $${yearlyOption.price /
                                100} charged on ${subStart.toLocaleDateString()}`}
                            </span>
                          </div>
                        </div>
                      </div>

                      <hr className="solid" />
                      <span className="check_terms">
                        <input
                          type="checkbox"
                          checked={isSubscribed}
                          onChange={(e) => {
                            setIsOpen(e.target.checked);
                            setIsSubscribed(e.target.checked);
                          }}
                        />
                        &nbsp;&nbsp;<Link>Accept Terms & Conditions</Link>
                      </span>
                      <p className="terms_note">
                        Must be accepted to continue checkout
                      </p>
                    </div>
                  </form>
                </div>
                <div className="checkout_BTN StripeCheckout">
                  {!isSubscribed || btnDisabled ? (
                    <button
                      className="StripeCheckout DisabledStripeCheckout"
                      disabled={!isSubscribed || btnDisabled}
                    >
                      <span>Checkout</span>
                    </button>
                  ) : (
                    <button
                      className="StripeCheckout"
                      onClick={handleCheckoutAlert}
                    >
                      <span>Checkout</span>
                    </button>
                  )}
                  <StripeCheckout
                    stripeKey={process.env.REACT_APP_STRIPE_LIVE_KEY}
                    token={Subscription}
                    billingAddress
                    shippingAddress
                    amount={subCheck}
                    name="Payment details"
                    disabled={!isSubscribed || btnDisabled}
                    label="Checkout"
                    triggerEvent="onClick"
                    email={userEmail}
                  >
                    <button
                      ref={stripeCheckoutRef}
                      style={{ display: "none" }}
                      className="DisabledStripeCheckout"
                    />
                  </StripeCheckout>
                </div>
              </div>
            </div>
          ) : (
            <Loader />
          )}
        </div>
      </section>

      <Modal className="modal fade modal-xl" show={isOpen}>
        <Modal.Header>
          <h4 className="terms-Condition">ReSeller Terms of Use</h4>
          <div className="flex-grow-1 terms-Conditionbtn align-items-center">
            <button
              type="button"
              className="btn btn-primary "
              onClick={() => {
                acceptCondition();
              }}
            >
              Accept
            </button>
            <button
              type="button"
              onClick={() => {
                cancelCondition();
              }}
              className="btn btn-danger"
            >
              Cancel
            </button>
          </div>
        </Modal.Header>
        <form>
          <Modal.Body>
            <TermsCondition />
          </Modal.Body>
        </form>
      </Modal>
      <ToastContainer autoClose={5000} position="top-center" />
    </>
  );
};
export default CheckoutForm;
